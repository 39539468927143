$brand-primary: #03408f;
$brand-secondary: #5cb85c;

$background: #f6f6f6;

$grid-gutter-width: 10px;

$sidepanel-animation-time: 0.3s;
$sidepanel-background: darken($brand-primary, 15%);
$sidepanel-button-height: 42px;
$sidepanel-width: 280px;
$sidepanel-width-off: 60px;
$sidepanel-icons: $brand-secondary;
$sidepanel-color: #a8b6cf;

$top-bar-background: darken($brand-primary, 20%);
$top-bar-height: 64px;

$alert-success-border: transparent;
$alert-info-border: transparent;
$alert-warning-border: transparent;
$alert-danger-border: transparent;

$alert-border-radius: 2px;

$linkcolor-transition-time: 0.15s;

$hover-gray: #f8f8f8;

$border-subtle: 1px solid #eee;

$padding: 16px;